<template>
  <div>
    <div class='bg-gray-100 p-4 border-b text-sm flex flex-col lg:flex-col items-start justify-start relative'>
      <div class='flex-grow-0 flex-shrink-0'>
        <div v-if='abstract.extraInfo.cohost' class='flex flex-row justify-start items-start mb-2'>
          <label class='w-60 text-gray-500 uppercase flex-shrink-0'>CO-HOSTS & SUBMISSION CATEGORY</label>
          <div class='uppercase flex-grow'>{{abstract.extraInfo.cohost}}</div>
        </div>
        <div v-if='abstract.preferredPresentationType' class='flex flex-row justify-start items-start mb-2'>
          <label class='w-60 text-gray-500 uppercase flex-shrink-0'>Presentation Type:</label>
          <div class='uppercase flex-grow'>{{abstract.preferredPresentationType}}</div>
        </div>
        <div v-if='abstract.category' class='flex flex-row justify-start items-start mb-2'>
          <label class='w-60 text-gray-500 uppercase flex-shrink-0'>Format Classification:</label>
          <div class='uppercase flex-grow'>{{abstract.category}}</div>
        </div>
        <div v-if='abstract.field' class='flex flex-row justify-start items-start mb-2'>
          <label class='w-60 text-gray-500 uppercase flex-shrink-0'>Topic:</label>
          <div class='uppercase flex-grow'>{{abstract.field}}</div>
        </div>
        <div v-if='abstract.subtopic' class='flex flex-row justify-start items-start mb-2'>
          <label class='w-60 text-gray-500 uppercase flex-shrink-0'>Subtopic:</label>
          <div class='uppercase flex-grow'>{{abstract.subtopic}}</div>
        </div>
      </div>
      <div class='flex flex-row justify-start items-start mb-2'>
        <label class='w-60 text-gray-500 uppercase flex-shrink-0'>Authors:</label>
        <div class='flex-grow'>
          <div v-for='(author, index) in abstract.authors'
            :key='`overview-author-${index}`'
            class='mb-1 border-b'>
            <div class=''>
              {{author.lastName}}, {{author.firstName}}
              <span v-if='author.isPresenter'     class='px-1 text-blue-500'>*Presenter</span>
              <span v-if='author.isCorrespondant' class='px-1 text-blue-500'>*Corresponding Author</span>
            </div>
            <div v-for='affiliation in author.affiliations'
              :key='`affiliation-${affiliation.department}, ${affiliation.affiliation}, ${affiliation.country}`'
              class='text-xs'>
              {{ authorBackground(affiliation) }}
            </div>
            <div class='text-xs'>
              <span v-if='author.email'>{{author.email}}</span>
              <span v-if='author.phoneNumber'>, {{author.phoneNumber}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if='showAcceptedStatusBadge'
        class='absolute w-full h-full z-10 top-0 left-0'>
        <div 
          class='absolute z-20 top-4 right-4 py-2 pl-3 pr-4 rounded border uppercase text-white'
          :class='statusBasedTagColor(abstract.acceptStatus)'>
          <component :is='statusBasedIcon(abstract.acceptStatus)' class='inline-block h-4 mb-0.5' />
          {{abstract.acceptStatus}} 
          <span v-if='abstract.acceptStatus === "accepted"'>as <strong>{{abstract.acceptedPresentationType}}</strong></span>
        </div>
        <div 
          class='absolute w-full h-full z-10 top-0 left-0 opacity-50'
          :class='statusBasedCoverColor(abstract.acceptStatus)'> </div>
      </div>
    </div>
    <div class='p-8'>
      <div class='text-center'>
        <h1 class='text-xl font-bold mb-4'>{{abstract.title}}</h1>
        <div class='flex flex-row justify-center items-center gap-x-2'>
          <div v-for='author in abstract.authors'
            :key='`preview-author-${author.authorIndex}`' class='text-sm'>
            <span :class='presentorClass(author)'>{{author.lastName}}, {{author.firstName}}</span>
            <sup v-for='affiliation in author.affiliations'
                :key='`affiliation-${authorBackground(affiliation)}`'>
                {{authorBackgroundIndex(affiliation)}}
            </sup>
            <sup>
              <mail-icon v-if='author.isCorrespondant' class='inline' size='12'/>
            </sup>
          </div>
        </div>
        <div class='flex flex-row justify-center items-center gap-x-1'>
          <div v-for='(background, index) in authorBackgrounds' :key='`preview-author-background-${background}`' class='text-sm'>
            <div class='italic'><sup>{{index + 1}}</sup>{{background}}</div>
          </div>
        </div>
      </div>
      <div class='my-8 text-sm'>
        <div v-if='articleType'>
          <h2 class='mb-0 font-semibold'>Objectives:</h2>
          <p class='ql-editor mb-6' style='min-height: 0; padding-left: 0;' v-html='abstract.introduction'></p>

          <h2 class='mb-0 font-semibold'>Methods:</h2>
          <p class='ql-editor mb-6' style='min-height: 0; padding-left: 0;' v-html='abstract.methodsAndMaterials'></p>

          <h2 class='mb-0 font-semibold'>Results:</h2>
          <p class='ql-editor mb-6' style='min-height: 0; padding-left: 0;' v-html='abstract.results'></p>

          <h2 class='mb-0 font-semibold'>Conclusions:</h2>
          <p class='ql-editor mb-6' style='min-height: 0; padding-left: 0;' v-html='abstract.conclusions'></p>
        </div>
        <div v-else-if='caseType'>
          <h2 class='mb-0 font-semibold'>Introduction:</h2>
          <p class='ql-editor mb-6' style='min-height: 0; padding-left: 0;' v-html='abstract.introduction'></p>
          <h2 class='mb-0 font-semibold'>Case Description:</h2>
          <p class='ql-editor mb-6' style='min-height: 0; padding-left: 0;' v-html='abstract.casePresentation'></p>
          <h2 class='mb-0 font-semibold'>Discussion:</h2>
          <p class='ql-editor mb-6' style='min-height: 0; padding-left: 0;' v-html='abstract.discussion'></p>
        </div>
        <div v-else-if='technicalType'>
          <h2 class='mb-0 font-semibold'>Technical Notes:</h2>
          <p class='ql-editor mb-6' style='min-height: 0; padding-left: 0;' v-html='abstract.technicalNote'></p>
        </div>
        <h2 class='mb-0 font-semibold'>Acknowledgements:</h2>
        <p class='ql-editor mb-6' style='min-height: 0; padding-left: 0;' v-html='abstract.acknowledgements'></p>
        <h2 class='mb-0 font-semibold'>Figure / Table:</h2>
        <p class='ql-editor mb-6' style='min-height: 0; padding-left: 0;' v-html='abstractExtrainfoFigures'></p>
        <div class='flex flex-row justify-start items-start mb-4'>
          <h3 class='font-semibold'>Keywords:</h3>
          <p class=''>{{abstractKeywordsString}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MailIcon, BanIcon, CheckIcon } from 'vue-tabler-icons'
import uniq from 'lodash/uniq'

export default {
  name: 'AbstractSubmissionPreview',
  props: [
    'abstract',
    'showAcceptedStatusBadge',
  ],
  components: {
    MailIcon,
    CheckIcon,
    BanIcon,
  },
  computed: {
    articleType () {
      return this.abstract.category.match(/article/gi)
    },
    caseType () {
      return this.abstract.category.match(/case/gi)
    },
    technicalType () {
      return this.abstract.category.match(/technical/gi)
    },
    abstractKeywordsString () {
      return this.abstract.keywords.join(', ')
    },
    authorBackgrounds () {
      let allBackgrounds = this.abstract.authors.map(author => author.affiliations).flat().sort((a,b) => a.affiliationIndex - b.affiliationIndex)
      return uniq(allBackgrounds.map(affiliation => this.authorBackground(affiliation)))
    },
    abstractExtrainfoFigures () {
      return this.abstract && this.abstract.extraInfo ? this.abstract.extraInfo.figures : ''
    },
  },
  methods: {
    authorBackgroundIndex (affiliation) {
      return this.authorBackgrounds.findIndex(background => background === this.authorBackground(affiliation)) + 1
    },
    authorBackground (affiliation) {
      return `${affiliation.department}, ${affiliation.affiliation}, ${affiliation.country}`
    },
    presentorClass (author) {
      return author.isPresenter ? 'underline' : ''
    },
    statusBasedTagColor (status) {
      switch (status) {
        case 'accepted':
          return 'bg-green-600'
        case 'rejected':
          return 'bg-red-600'
        default: 
          return 'bg-transparent'
      }
    },
    statusBasedCoverColor (status) {
      switch (status) {
        case 'accepted':
          return 'bg-green-50'
        case 'rejected':
          return 'bg-red-50'
        default: 
          return 'bg-transparent'
      }
    },
    statusBasedIcon (status) {
      switch (status) {
        case 'accepted':
          return 'check-icon'
        case 'rejected':
          return 'ban-icon'
        default: 
          return 'span'
      }
    },
  },
}

</script>
